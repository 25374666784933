import { template as template_63d8e797db394171877a8343c862ce16 } from "@ember/template-compiler";
const SidebarSectionMessage = template_63d8e797db394171877a8343c862ce16(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
