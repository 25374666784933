import { template as template_2d23bb4bc7384b879ee779735aca6368 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_2d23bb4bc7384b879ee779735aca6368(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
