import { template as template_d5025712277b4f42a556c4a470136e88 } from "@ember/template-compiler";
const FKLabel = template_d5025712277b4f42a556c4a470136e88(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
