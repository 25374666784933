import { template as template_2f665fbeddec499297caf6456a83e45d } from "@ember/template-compiler";
const WelcomeHeader = template_2f665fbeddec499297caf6456a83e45d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
