import { template as template_c0387a1e0c5643d1878554c83be67d3c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_c0387a1e0c5643d1878554c83be67d3c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
